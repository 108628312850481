import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FeatureObjectTypes from "../components/feature-object-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import FeatureCard from "../components/feature-card"
import FeatureContainerApi from "../components/feature-container-api"
import FeatureMetadataMasks from "../components/feature-metadata-masks"
import SuccessStoryCard from "../components/success-story-card"
import Customers from "../components/customers"
import FeatureTcpNumpy from "../components/feature-tcp-numpy"

const ProductContainer = ({ data }) => (
	<Layout>
		<SEO
			title="Image and Video Anonymization Container Solution - Securely Protect Privacy with Celantur"
			description="Ensure the protection of personal information with Celantur's image and video anonymization delivered via Docker Container. Visit our website to learn how you can deploy our cutting-edge software into your on-premise and cloud infrastructure."
		/>

		<section className="pt-4 pt-md-11 pb-10 pb-sm-14">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-12 cold-md-5 col-lg-6 order-md-2">
						<Img fluid={data.headerImage.childImageSharp.fluid} />
					</div>
					<div className="col-12 cold-md-7 col-lg-6 order-md-1">
						<h1 className="display-5 text-center text-md-left text-primary font-weight-bold">Celantur Container</h1>
						<p className="lead text-center text-md-left text-muted mb-5">
							<strong>Fully-automated image and video blurring software.</strong> Deploy highly scalable into your
							on-premise and cloud data workflows.
						</p>
						<div className="text-center text-md-left">
							<Link to="/contact/" className="btn btn-primary lift event-start-demo">
								Request Demo <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
							</Link>
							<p className="text-muted font-size-sm mt-1">
								You'll get a time-limited PRO license, containing all features
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section className="py-5">
			<div className="container">
				<Customers />
			</div>
		</section>

		<section className="py-5">
			<div className="container">
				<FeatureObjectTypes />
			</div>
		</section>

		<section className="py-8">
			<div className="container">
				<h2 className="font-weight-bold text-center">
					Easy to <span className="text-success">integrate</span>
				</h2>
				<FeatureContainerApi />
				<FeatureTcpNumpy />
			</div>
		</section>

		<section className="py-5">
			<div className="container">
				<FeatureMetadataMasks />
			</div>
		</section>

		<section className="my-8">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-12 col-md-10 text-center mb-5">
						<h2 className="font-weight-bold">Success Stories</h2>
						<p className="font-size-lg text-gray-700">Read how industry-leading companies use Celantur Container to solve their privacy challenges.</p>
					</div>
				</div>
				<div className="row">
					<SuccessStoryCard
						title={
							<>
								<mark>greehill</mark> provides Green Asset Management without privacy concerns
							</>
						}
						link="/success-story-greehill/"
						image={data.greehillPointcloud.childImageSharp.fluid}
					/>
					<SuccessStoryCard
						title={
							<>
								<mark>Fortune 500 tech company</mark> develops L4 self-driving vehicle technology in a GDPR-compliant
								manner
							</>
						}
						link="/success-story-fortune-500-self-driving/"
						image={data.traffic.childImageSharp.fluid}
					/>
				</div>
			</div>
		</section>

		
	</Layout>
)

/*

<section className="py-5">
			<div className="container">
				<div className="row mt-n7">
					<FeatureCard headline="Fully automated blurring" content="Faces, persons, license plates, vehicles" />
					<FeatureCard headline="REST API" content="test" />
					<FeatureCard headline="Extract Metadata" content="test" />
					<FeatureCard headline="Extract Segmentation Masks" content="test" />
					<FeatureCard headline="Offline Mode" content="test" />
					<FeatureCard headline="Numpy Array Support" content="test" />
					<FeatureCard headline="Output Format Conversion" content="test" />
					<FeatureCard headline="Fetch from Cloud Storage" content="test" />
				</div>
			</div>
		</section>

*/

export default ProductContainer

export const logo = graphql`
	fragment imageProductPage on File {
		childImageSharp {
			fluid(maxWidth: 640) {
				...GatsbyImageSharpFluid
			}
		}
	}
`

export const query = graphql`
	query {
		greehillPointcloud: file(relativePath: { eq: "greehill-pointcloud.png" }) {
			...imageProductPage
		}
		traffic: file(relativePath: { eq: "traffic.jpg" }) {
			...imageProductPage
		}
		headerImage: file(relativePath: { eq: "container-product.png" }) {
			...imageProductPage
		}
	}
`
